<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import store from './store'
import qs from 'qs'
export default {
  async mounted() {
    const query = qs.parse(window.location.href.split('?')[1])
    if (query && query.key) {
      localStorage.setItem('token', query.key)
      await store.dispatch('getToken', query.key)
    }
  }
}
</script>
<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
