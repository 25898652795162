import axios from 'axios'
import qs from 'qs'
let _axios = axios.create({
  baseURL: 'https://zhzl.hzxhct.cn/dp-api',
  // baseURL: '/proxy',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    gatewayCompany: 'zt'
  },
  timeout: 60000 // 默认一分钟
})

_axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    config.headers.Authorization = token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
_axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)
export default _axios
