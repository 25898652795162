import Vue from 'vue'
import Vuex from 'vuex'
import Xhr from '../xhr/index'
import router from '../router'

import { Message } from 'element-ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      userName: '',
      phone: '',
      isLook: '0',
      outTime: ''
    }
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload
    },
    resetUserInfo(state, payload) {
      state.userInfo = {
        userName: '',
        phone: '',
        isLook: '0',
        outTime: ''
      }
    }
  },
  actions: {
    async getToken({ state, commit }, payload) {
      try {
        // const { data } = await Xhr.checkByToken({ key: `58d23c257dcb486088b66289feb4ce44` })
        const { data } = await Xhr.checkByToken({ key: payload })
        if (!data.success) {
          Message.error(data.message)
          //重置用户信息
          commit('resetUserInfo')
          // 跳转到 404
          router.push('/404')
        } else if (data.data && data.data.userName) {
          // 登录成功！
          commit('setUserInfo', data.data)
        } else {
          // 重置用户信息
          commit('resetUserInfo')
        }
      } catch (error) {
        Message.error('错误')
      }
    }
  },
  getters: {
    hasDetailPermission: state => state.userInfo.isLook === '1'
  },
  modules: {}
})
