import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'root',
    redirect: '/login'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/bull',
    name: 'bull',
    component: () => import('../views/bullframe')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  }
]
const router = new VueRouter({
  mode: 'hash',
  routes
})
router.beforeEach((to, from, next) => {
  // 是否登陆页面
  let isLoginPage = to.path === '/login' || to.path === '/' || to.path === '/bull'
  // 不需要拦截的页面
  if (isLoginPage) {
    next()
  } else {
    // 判断是否存在 currentUserToken
    if (sessionStorage.getItem('token')) {
      // 存在放行
      next()
    } else {
      // Mmessage.error('请先登录')
      router.push({
        path: '/login'
      })
    }
  }
})

export default router
